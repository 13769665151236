import axios from 'axios'

const instanceImage = axios.create({
    // baseURL: 'https://api.synergyhms.com/hms/v1.0',
    // baseURL: 'http://48.216.248.163:9100/authref/v1.0',
    baseURL: 'https://hmsapi.synergyhms.com/hms/v1.0',
    // baseURL: 'http://localhost:9100/hms/v1.0',
    // baseURL: 'http://localhost:8010/proxy/hms/v1.0',
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});

instanceImage.interceptors.request.use(
    config => {
        const token = sessionStorage.getItem('admin-token');
        const hospital = JSON.parse(sessionStorage?.getItem('hmsv2-hospital')) || null;

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        config.headers.dbname = hospital?.dbName || '';

        return config;
    },
    error => {
        return Promise.reject(error)
    }
);

export default instanceImage
