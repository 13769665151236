import { useMutation, useQuery, useQueryClient } from 'react-query';
import instance from './app-index';
import { notification } from 'antd';
import instanceImage from './app-image';

// Insurance Company APIs
const getAllInsurancePatientCompany = async (patientId) => await instance.get(`/insuranceCompanyPatient/patient/${patientId}`);

export function getPatientInsurances(patientId) {
    return instance.get(`/insuranceCompanyPatient/patient/${patientId}`);
}

const getAllPatients = async () => await instance.get('/patient');

const getPatientByBranch = async (branchId) => await instance.get(`/patient/branch/${branchId}`);

const getPatientByVisit = async (branchId) => await instance.get(`/patient/visit/${branchId}`);

const getPatientByVisitId = async (visitId) => await instance.get(`/patient/visitid/${visitId}`);

const getPatientVitalsByVisit = async (visitId) => await instance.get(`/vital/visit/${visitId}`);

const searchpatient = async (payload) => await instance.post(`/patient/search`, payload);

// const patientRoamingSearch = async (patientCardNumber) => await instance.get(`/patient/search/card/${patientCardNumber}`);

const addPatientInsuranceCompany = async (payload) => await instance.post(`/insuranceCompanyPatient`, payload);

const editPatientInsuranceCompany = async (payload) => await instance.put(`/insuranceCompanyPatient/${payload.insuranceCompanyPatientId}`, payload);

const getAllEthnicity = async () => await instance.get(`/ethnicity`);

const addPatient = async (payload) => await instance.post('/patient', payload);

const addBulkPatient = async (payload) => await instance.post('/patient/bulk', payload);

const editPatient = async ({ patientId, payload }) => await instance.put(`/patient/${patientId}`, payload);

const addVitals = async (payload) => await instance.post('/vital', payload);

const editVitals = async ({payload, vitalId}) => await instance.put(`/vital/${vitalId}`, payload);

const getPatientVitals = async (patientId) => await instance.get(`/vital/patient/${patientId}`);

const getVisitVitals = async (patientId, visitId) => await instance.get(`/vital/patient/${patientId}/${visitId}`);

//Patient Issue APIs
const getPatientAllergies = async (patientId, issueType) =>
    await instance.get(`/patientIssue/patient/${patientId}/${issueType}`);

const addPatientAllergy = async (payload) => await instance.post('/patientIssue', payload);

const editPatientAllergy = async ({ patientIssueId, payload }) =>
    await instance.put(`/patientIssue/${patientIssueId}`, payload);

//Patient Consent APIs
const getConsentByVisit = async (visitId) =>
    await instance.get(`/patientConsent/visit/${visitId}`);

const getConsentByPatient = async (patientId) =>
    await instance.get(`/patientConsent/patient/${patientId}`);

const addPatientConsent = async (payload) => await instance.post('/patientConsent', payload);

const editPatientConsent = async ({ patientConsentId, payload }) =>
    await instance.put(`/patientConsent/${patientConsentId}`, payload);

//Patient Vitals Queries
export function useAddVitals() {
    return useMutation(addVitals);
}

export function useEditVitals() {
    const queryClient = useQueryClient(); 

    return useMutation(editVitals, {onSuccess:()=>{
        queryClient.invalidateQueries('getPatientVitals');
        queryClient.invalidateQueries('getVisitVitals');
    }});
}

export function useGetPatientVitals(patientId) {
    return useQuery(['getPatientVitals'], () => getPatientVitals(patientId));
}

export function useGetVisitVitals(patientId, visitId) {
    return useQuery(['getVisitVitals'], () => getVisitVitals(patientId, visitId));
}

// Patient Queries
export function useGetAllPatientInsuranceCompanies(patientId) {
    return useQuery(['getAllInsurancePatientCompany'], () => getAllInsurancePatientCompany(patientId));
}

export function useGetAllPatients() {
    return useQuery(['getAllPatients'], getAllPatients);
}

export function useGetPatientByBranch(branchId) {
    return useQuery(['getPatientByBranch'], () => getPatientByBranch(branchId));
}

export function useGetPatientByVisit(branchId) {
    return useQuery(['getPatientByVisit', branchId],
        () => getPatientByVisit(branchId), { enabled: !!branchId });
}

export function useGetPatientByVisitId(visitId) {
    return useQuery(['getPatientByVisitId', visitId],
        () => getPatientByVisitId(visitId), { enabled: !!visitId });
}


export function useGetPatientVitalsByVisit(vitalId) {
    return useQuery(['getPatientVitalsByVisit'], () => getPatientVitalsByVisit (vitalId));
}

export function useSearchPatient() {
    return useMutation(searchpatient);
}

export function useAddBulkPatient() {
    const queryClient = useQueryClient();    

    return useMutation(addBulkPatient, {onSuccess:()=>{
        notification.success({ message: "Patients added successfully" });
        queryClient.invalidateQueries('getPatientByVisitId');
    }});
}

export function useAddPatientInsuranceCompany() {
    const queryClient = useQueryClient();    

    return useMutation(addPatientInsuranceCompany, {onSuccess:()=>{
        notification.success({ message: "Insurance company added successfully" });
        queryClient.invalidateQueries('getAllInsurancePatientCompany');
    }});
}

export function useEditPatientInsuranceCompany() {
    return useMutation(editPatientInsuranceCompany);
}

export function useGetAllEthnicity() {
    return useQuery(['getAllEthnicity'], getAllEthnicity);
}

export function useAddPatient() {
    return useMutation(addPatient);
}

export function useEditPatient() {
    return useMutation(editPatient);
}

//send patient to BC if he/she has not been registered there
const sendPatientToMBC = async (patientId) => await instance.get(`/patient/sendToBC/${patientId}`);

export function useSendPatientToMBC(patientId) {
    return useQuery(['sendPatientToBC', patientId], () => sendPatientToMBC(patientId),{
        enabled: !!patientId,
      });
}


export function getPatients() {
    return instance.get('/patient');
}

export function sendPatientToBC(patientId) {
    return instance.get(`/patient/sendToBC/${patientId}`);
}

export function sendPendingPatientsToBC() {
    return instance.get('/patient/sendPendingPatientToBC');
}

export function getPatientById(patientId) {
    return instance.get(`/patient/${patientId}`);
}

export function getPatientRoaming(patientCardNumber) {
    return instance.get(`/patient/search/card/${patientCardNumber}`);
}

export function getPatientByPatientId(patientId) {
    return instance.get(`/patient/patientId/${patientId}`);
}

export function getEthnicity() {
    return instance.get('/ethnicity');
}

//generate card number for patients
export function generateCardNumberForPatients() {
    return instance.get('/patient/generate-card');
}

export function getInsurancePatientCompany({
    patientId = "",
}) {
    return instance.get(`/insuranceCompanyPatient/patient/${patientId}`);
}

export function insuranceCompanyPatient({
    insuranceCompanyHospitalId = '',
    patientId = '',
    status = '',
    type = '',
    planName = '',
    policyNumber = '',
    subscriberFName = '',
    subscriberMName = '',
    subscriberLName = '',
    relationship = '',
    subscriberEmployer = '',
    copay = '',
    copayType = '',
    policyType = '',
    startDate = '',
    expiryDate = '',
    meansOfVerificationType = '',
    createdBy = ''
} = {}) {
    return instance.post('/insuranceCompanyPatient', {
        insuranceCompanyHospitalId: insuranceCompanyHospitalId,
        patientId: patientId,
        status: status,
        type: type,
        planName: planName,
        policyNumber: policyNumber,
        subscriberFName: subscriberFName,
        subscriberMName: subscriberMName,
        subscriberLName: subscriberLName,
        relationship: relationship,
        subscriberEmployer: subscriberEmployer,
        copay: copay,
        copayType: copayType,
        policyType: policyType,
        startDate: startDate,
        expiryDate: expiryDate,
        meansOfVerificationType: meansOfVerificationType,
        createdBy: createdBy
    });
}

export function getVisitByPatientId(patientId) {
    return instance.get(`/patientVisit/patient/${patientId}`);
}

export function addPatientVisit({
    patientId = '',
    branchId = '',
    visitCategory = '',
    visitDate = '',
    visitTypeId = '',
    visitDescription = '',
    referredHospital = '',
    referredProvider = '',
    preferredProvider = '',
    sensibility = '',
    status = '',
    createdBy = ''

} = {}) {
    if (patientId === '' || branchId === '' || visitCategory === '' || visitDate === '' ||
        visitDescription === '' || preferredProvider === '' || sensibility === ''
    ) {
        return Promise.reject(new Error('Field cannot be empty'));
    }
    return instance.post('/visit', {
        patientId: patientId,
        branchId: branchId,
        visitCategory: visitCategory,
        visitDate: visitDate,
        visitTypeId: visitTypeId,
        visitDescription: visitDescription,
        referredHospital: referredHospital,
        referredProvider: referredProvider,
        preferredProvider: preferredProvider,
        sensibility: sensibility,
        status: status,
        createdBy: createdBy
    });
}

//Patient Visit API
const getPatientVitById = async (patientId) => await instance.get(`/patientVisit/patient/${patientId}`);


const getVisitById = async (patientVisitId) => await instance.get(`/patientVisit/${patientVisitId}`)

// Patient Queries
export function useGetPatientVisitId(patientId) {
    return useQuery(['getPatientVisit', patientId], () => getPatientVitById(patientId), { enabled: !!patientId });
}

export function useGetVisitById(patientVisitId) {
    return useQuery(['getVisitById', patientVisitId], () => getVisitById(patientVisitId), { enabled: !!patientVisitId });
}

const getAllPatientByBranch = async (branchId) => await instance.get(`/patient/branch/${branchId}`);

export function useGetAllPatientByBranch(branchId) {
    return useQuery(['getAllPatientByBranch'], () => getAllPatientByBranch(branchId));
}

const getPatientByBranchId = async (branchId) => await instance.get(`/patient/visit/${branchId}`);

export function useGetPatientByBranchId(branchId) {
    return useQuery(['getPatientByBranchId'], () => getPatientByBranchId(branchId));
}

// Patient Visit API
const getPatientDocument = async (patientId) => await instance.get(`/media/patient/${patientId}`);
const uploadPatientDocument = async (payload) => await instance.post('/media', payload);
const uploadPatientFileName = async ({ filename, payload }) => await instanceImage.post(`/media/upload/${filename}`, payload);

//Patient History API
const getPatientHistory = async (patientId) => await instance.get(`/historyDetail/patient/${patientId}`);

export function useGetPatientHistory(patientId) {
    return useQuery(['getPatientDocument', patientId], () => getPatientHistory(patientId), { enabled: !!patientId });
}

export function useGetPatientDocument(patientId) {
    return useQuery(['getPatientDocument'], () => getPatientDocument(patientId));
}

export function useUploadPatientDocument() {
    return useMutation(uploadPatientDocument, {
        onSuccess: () => {
            notification.success({ message: 'Document uploaded successfully' });
        },
        onError: () => {
            notification.error({ message: 'Document upload failed' });
        }
    });
}

export function useUploadPatientFileName() {
    return useMutation(uploadPatientFileName, {
        onSuccess: () => {
            notification.success({ message: 'Document uploaded successfully' });
        },
        onError: () => {
            notification.error({ message: 'Document upload failed' });
        }
    });
}

//Patient Issues Queries
export function useGetPatientAllergies(patientId, issueType) {
    return useQuery(['getPatientAllergies'], () => getPatientAllergies(patientId, issueType));
}

export function useAddPatientAllergy() {
    const queryClient = useQueryClient();
    return useMutation(addPatientAllergy,{
        onSuccess: () => {
          queryClient.invalidateQueries(['getPatientAllergies']);
        },
      });
}

export function useEditPatientAllergy() {
    return useMutation(editPatientAllergy);
}

//Patient Consent Queries
export function useGetConsentByVisit(visitId) {
    return useQuery(['getConsentByVisit', visitId], () => getConsentByVisit(visitId), { enabled: !!visitId });
}

export function useGetConsentByPatient(patientId) {
    return useQuery(['getConsentByPatient'], () => getConsentByPatient(patientId));
}

export function useAddPatientConsent() {
    return useMutation(addPatientConsent);
}

export function useEditPatientConsent() {
    return useMutation(editPatientConsent);
}
