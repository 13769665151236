import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import { useSelectedUser } from "./staff/SelectedUserProvider";

function Breadcrumb({ link1, link2, title2, title1, menuItem }) {

    const { setMenuItem, hospital } = useSelectedUser();

    useEffect(() => {

        setMenuItem(menuItem);
    }, [menuItem]);

    return (
        <div className="page-header">
            <div className="d-flex justify-content-between">
                <div className="">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to={link1}>{title1} </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <i className="feather-chevron-right">
                                <FeatherIcon icon="chevron-right" />
                            </i>
                        </li>
                        {/* <li className="breadcrumb-item active">{link2}</li> */}
                        <li className={`breadcrumb-item ${location.pathname === link2 ? 'active' : ''}`}>
                            <Link to={link2}>{title2}</Link>
                        </li>
                    </ul>
                </div>
                <div className="text-success" style={{fontWeight: '500'}}>
                    {hospital.name}
                </div>
            </div>
        </div>
    )
}

Breadcrumb.propTypes = {
    link1: PropTypes.string,
    link2: PropTypes.string,
    menuItem: PropTypes.any,
    title1: PropTypes.string,
    title2: PropTypes.string
};

export default Breadcrumb;
